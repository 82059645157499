<template>
  <div class="main">

    <div class="pop-up" v-show="isShow">
      <div class="msg">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
        <h2 style="color: #fff;">正在连接中...</h2>
      </div>
    </div>

    <div id="screen">
    </div>

    <div class="n_button">
      <button id="noVNC_clipboard_button" class="btn btn-light" @click="showClipboard" title="剪贴板">剪贴板</button>
      <button class="btn btn-light" @click="sendCtrlAltDel">Ctrl + Alt + Delete</button>
      <img :src="fullImg" @click="fullScreen"/>
    </div>

    <div class="n_button" v-show="ifClipboard">
      <div id="noVNC_clipboard" class="noVNC_panel noVNC_open">
        <textarea id="noVNC_clipboard_text" rows="5"></textarea>
      </div>
    </div>

  </div>

</template>

<script>

import RFB from '@novnc/novnc/core/rfb'
import moment from "moment/moment";

export default {
  data() {
    return {
      cRfb: null,
      isShow: true,
      autoTimer: null,
      vm: [],
      fullImg: require('../../../../assets/images/full.svg'),
      flags: false,
      position: {
        x: 0,
        y: 0
      },
      nx: '',
      ny: '',
      dx: '',
      dy: '',
      xPum: '',
      yPum: '',
      moveBtn: {},
      timer: null,
      currentTop: 0,
      ifClipboard: false,
      timerEnv: null
    }
  },
  methods: {
    disconnectedFromServer() {
      this.autoTimer = setInterval(() => {
        console.log("自动连接")
        this.init()
        clearInterval(this.autoTimer);
        this.autoTimer = null
      }, 5000)
    },
    success() {
      this.isShow = false
    },
    clipboardReceive(e) {
      document.getElementById('noVNC_clipboard_text').value = e.detail.text;
    },
    clipboardSend() {
      const text = document.getElementById('noVNC_clipboard_text').value;
      this.cRfb.clipboardPasteFrom(text);
    },
    sendCtrlAltDel() {
      this.cRfb.sendCtrlAltDel()
    }
    ,
    connectVNC() {
      const oTarget = document.getElementById('screen')

      var ws = document.location.protocol == 'http:' ? 'ws:' : 'wss:'

      let rfb = new RFB(oTarget, ws + '//' + this.vm.node_vnc_link + this.vm.id, {
        credentials: {password: this.vm.vnc_passwd}
      })

      rfb.addEventListener('connect', this.success)
      rfb.addEventListener('disconnect', this.disconnectedFromServer)
      rfb.addEventListener("clipboard", this.clipboardReceive);
      rfb.clickViewport = true
      rfb.scaleViewport = true
      rfb.resizeSession = true
      this.cRfb = rfb

    },
    init() {
      var vm = this

      document.getElementById("noVNC_clipboard_text")
          .addEventListener('change', this.clipboardSend);

      this.$axios.post('/VM/GetUserEnv?envid=' + this.$route.query.id).then((res) => {
            if (!res.data.code)
              return false
            vm.vm = res.data.data
            if (vm.vm.vm_linux_vnc) {
              vm.vm.id = "VNC_" + vm.vm.id
            }
            vm.connectVNC()
          }
      )

      this.timerEnv = setInterval(() => {
        this.countDown()
      }, 60000)
    },
    fullScreen() {
      var element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    showClipboard() {
      this.ifClipboard = !this.ifClipboard
    },
    countDown() {
      this.$axios.post('/VM/GetUserEnv?envid=' + this.$route.query.id).then((res) => {
            if (!res.data.code)
              return false
            this.vm = res.data.data
          }
      )
      if (this.vm.length === 0)
        return -1;

      let createTime = new Date(this.vm['createTime']);

      createTime.setMinutes(createTime.getMinutes() + this.vm['usetime'])

      var nowDate = new Date()

      let dura = moment(createTime).format('x') - moment(nowDate).format('x')

      let tempTime = moment.duration(dura);

      if (tempTime.asMinutes() <= 15) {
        this.$message({
          showClose: true,
          duration: 0,
          center: true,
          message: '距离环境自动销毁还有15分钟，继续使用请延长时间',
          type: 'warning'
        });

        clearInterval(this.timerEnv)
      }
    },
    // 实现移动端拖拽
    btnDown() {
      this.flags = true;
      let touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.moveBtn.offsetLeft;
      this.dy = this.moveBtn.offsetTop;
    },
    btnMove() {
      if (this.flags) {
        let touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let clientWidth = document.documentElement.clientWidth;
        let clientHeight = document.documentElement.clientHeight;
        if (this.xPum > 0 && this.xPum < (clientWidth - this.moveBtn.offsetWidth)) {
          this.moveBtn.style.left = this.xPum + "px";
        }
        if (this.yPum > 0 && this.yPum < (clientHeight - this.moveBtn.offsetHeight)) {
          this.moveBtn.style.top = this.yPum + "px";
        }

        //阻止页面的滑动默认事件
        document.addEventListener("touchmove", this.handler, {
          passive: false
        });
      }
    },
    //鼠标释放时候的函数
    btnEnd() {
      this.flags = false;
      document.addEventListener('touchmove', this.handler, {
        passive: false
      });
    },
  }
  ,
  mounted() {
    const vm = this
    this.$nextTick(() => {
      vm.init()
    })
  }
}

</script>

<style>
body {
  margin: 0;
  height: 100%;
}

html {
  height: 100%;
}

#screen {
  flex: auto; /* fill remaining space */
  overflow: hidden;
}


.main {
  height: 100%;
  display: flex;
  flex-direction: column;

}

w
.top_bar button {
  background-color: #009695;
  border: none;
  color: white;
}

.n_button {
  position: absolute;
  right: 25px;
  top: 25px;
}

.n_button button {
  margin-right: 25px;
}

.n_button img {
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: white;
  border-radius: 3px;
}

.pop-up {
  position: fixed;
  left: 0px;
  top: 0px;
  background: #000;
  width: 100%;
  height: 100%;
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px
  }
  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px
  }
}


.pop-up .msg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.pop-up h2 {
  padding: 0;
  margin-left: 30px;
  font-size: 35px;
  letter-spacing: 10px;
}

.pop-up .circular {
  width: 50px;
  height: 50px;
}

.pop-up .path {
  animation: loading-dash 4s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 5;
  stroke: #fff;
  stroke-linecap: round;

}

#noVNC_clipboard_text {

  position: absolute;
  right: 0;
  top: 55px;
  width: 320px;
  min-width: 320px;
  height: 160px;
  min-height: 70px;
  box-sizing: border-box;
  max-width: 100%;
  /* minus approximate height of title, height of subtitle, and margin */
  max-height: calc(100vh - 10em - 25px);
}

</style>
